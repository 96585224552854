//Acciones publicas

//Nuevo socio formulario
$(document).on('click','#newsocio-seleccionarimagen',function(){
    $("#seleccion-avatar").click();
});
$(document).on('click','#newsocio-camara',function(){
    $("#camara-avatar").click();
});
$(document).on('change','#seleccion-avatar',function(){
    $("#camara-avatar").attr('disabled',true);
    $("#seleccion-avatar").attr('disabled',false);
});
$(document).on('change','#camara-avatar',function(){
    $("#seleccion-avatar").attr('disabled',true);
    $("#camara-avatar").attr('disabled',false);
});
$(document).on('change','#tipo_documento',function(){
    $("#label-dni").html($(this).val());
    if($(this).val() == "NIF"){
        $("#dni").attr('maxlength',9);
    }else if($(this).val() == "NIE"){
        $("#dni").attr('maxlength',9);
    }else if($(this).val() == "Pasaporte"){
        $("#dni").attr('maxlength',12);
    }else if($(this).val() == "Sin documento"){
        $("#dni").attr('required',false);
        $("#dni").attr('name',null);
    }
});
//Lector registro entrada
$(document).on("change","#lector_numero_socio",function(){
    var numSoc = $(this).val();
    var activo = 0;
    $.ajax({
        url: "socios/aux/"+numSoc,
        type: "GET",
        dataType: "json",
        success: function(data){
            activo = data['status'];
            $(".carnet").empty();
            if(data['avatar'] != null){
                var avatar_src = "/storage"+data['avatar'];
            }else{
                var avatar_src = "/storage/avatar/noavatar.jpg";
            }
            if(data['status'] == 1){
                var status = "<p class='carnet-estadoactivo'>Activo</p>";
            }else{
                var status = "<p class='carnet-estadonoactivo'>No activo</p>";
            }
            var actividades = "";
            $.each(data['assigned_actividades'],function(key,actividad){
                if(key == 0){
                    actividades += "<p class='carnet-actividad'>"+actividad['nombre']+" </p>";
                }else{
                    actividades += "<p class='carnet-actividad'> - "+actividad['nombre']+"</p>";
                }
            });
            var logos = "";
            $.each(data['assigned_actividades'],function(key,actividad){
                if(actividad['empresa_id'] != 1){
                    logos += "<img class='carnet-logogym' src='/storage/"+actividad['logo']+"' alt=''>";
                }
            });
            
            $(".carnet").append(`
                <div class="d-flex justify-content-between">
                    <img class="carnet-avatar" src="`+avatar_src+`" alt="">
                    <div id="carnet-qr"></div>
                </div>
                <p class="carnet-nombre">`+data['nombre']+` `+data['apellidos']+`</p>
                <p class="carnet-numerosocio">Socio número `+data['numero_socio']+`</p>
                <div class="d-flex justify-content-center">
                    `+actividades+`
                </div>
                `+status+`
                <div class="d-flex justify-content-around mt-4">
                    <img class="carnet-logogym" src="/storage/images/FITNESSFABRAGYMLOGO.png" alt="">
                    `+logos+`
                </div>
            `);
            var qrcode = new QRCode(
                "carnet-qr", 
                {
                    text: data['numero_socio'],
                    width: 150,
                    height: 150,
                    colorDark : "#000000",
                    colorLight : "#ffffff",
                    correctLevel : QRCode.CorrectLevel.H
            });
        },
        complete: function(){
            $.ajax({
                url: "controlentrada/"+numSoc+"/"+activo,
                type: "GET",
                dataType: "json",
                success: function(data){
                    $("#resultado-control-acceso h3").html(data['mensaje']);
                    $("#resultado-control-acceso").css("background-color",data['color']);
                }
            });
        }
    });
});
