//Users
$(document).on('click','.nuevoUserModal', function(){
    $("#_method").val("POST");
    $("#formUser").attr('action',"users");
    $("#fichaUserLabel").html("Creación de nuevo usuario");
    $(".campo").val("");
});

$(document).on('click','.btn-delete-user',function(){
    Swal.fire({
        title: "¿Estás seguro que deseas eliminar a este usuario?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: 'No eliminar'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            $("#form-delete-user-"+this.id.substr(16,20)).submit();
        } else if (result.isDenied) {
          Swal.fire("El usuario no se eliminó", "", "info");
        }
      });
});