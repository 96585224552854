//Socios
const Choices = require('./choices.js');
const fecha = new Date();
const ultimo_dia_mes = [31,28,31,30,31,30,31,31,30,31,30,31];
$(document).on('click','.abreSocioModal', function(){
    $("#fichaSocio").modal("show");
    var id = this.id.substr(6,25);
    var checksActividades = "";
    $("#_method").val("PUT");
    $("#formSocio").attr('action',"socios/"+id);
    $(".btnDeleteSocio").css("display","block");
    $(".btnDeleteSocio").attr('id',"delete-"+id);
    $("#avatar").attr('src',"storage/avatar/noavatar.jpg");
    $("#actividades").empty();
    $(".campoSocio").val("");
    $.ajax({
        url: "socios/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaSocioLabel").html("Edición de socio "+data['nombre']+" "+data['apellidos']);
            $.each(data,function(index,value){
                if(index == 'avatar'){
                    $("#contenedor-avatar").empty();
                    if(value === null){
                        $("#contenedor-avatar").append("<img src='storage/avatar/noavatar.jpg' class='d-block' width='300px'>");
                    }else{
                        $("#contenedor-avatar").append("<img id='image-"+data['id']+"' src='storage"+value+"' class='d-block' width='300px'><button  class='borraImagen' id='imgdel-"+data['id']+"' type='button'><i class='bi bi-trash3'></i></button>");
                    }
                   $("#contenedor-avatar").append("<input type='file' accept='.jpg,.png,.jpeg' class='campo form-control' name='avatar_file' id='avatar_file' style='text-align:center'>"); 
                }
                if(index == "actividades" && value!= null){
                    var choicesMulti = Array();
                    var valorselect = Array();
                    $("#actividades").append("<label>Actividad/es</label><select multiple class='campo form-control' name='actividades[]' value='' id='actividades-list' style='text-align:center'></select>");
                    $.each(value,function(index2,value2){
                        var selected = false;
                        if(value2['activa']){
                            $.each(data['assigned_actividades'],function(index3,value3){
                                valorselect.push(value3['id']);
                                if(value2['id'] == value3['id']){
                                    selected = true;
                                }
                            });
                            choicesMulti.push({
                                value:value2['id'],
                                label:value2['nombre'],
                                selected:selected,
                            });
                        }
                    });
                    var selectClases = new Choices('#actividades-list', {
                        choices: choicesMulti,
                        removeItemButton: true,
                        maxItemCount:-1,
                        searchResultLimit:-1,
                        renderChoiceLimit:-1,
                        allowHTML:false,
                        searchFields:"label",
                        noResultsText: 'Sin resultados',
                        noChoicesText:'Sin resultados',
                        placeholderValue:'Selecciona...',
                        itemSelectText: "",
                    }); 
                    $("#actividades").val(valorselect);
                }
                if(index == "tipo_documento"){
                    $("#tipo_documento").val(value).change();
                }else{
                    $("#"+index.toLowerCase()).val(value);
                }
            });
            if(data['observaciones'] != null && data['obsevaciones'] != ""){
                Swal.fire({
                    title: "Observaciones",
                    text: data['observaciones'],
                    icon: "info"
                });  
            }
        }
    }); 
});
$(document).on('click','.nuevoSocioModal', function(){
    $("#_method").val("POST");
    $("#formSocio").attr('action',"socios");
    $("#fichaSocioLabel").html("Creación de nuevo socio");
    $("#avatar").attr('src',"storage/avatar/noavatar.jpg");
    $(".btnDeleteSocio").css("display","none");
    $("#btnReciboSocio").css("display","none");
    $("#btnHistorialReciboSocio").css("display","none");
    $("#actividades").empty();
    $(".campoSocio").val("");
    $("#contenedor-avatar").empty();
    $("#contenedor-avatar").append("<img src='storage/avatar/noavatar.jpg' class='d-block' width='300px'><div class='mb-3'></div>");
    $("#contenedor-avatar").append("<input type='file' accept='.jpg,.png,.jpeg' class='campo form-control' name='avatar_file' id='avatar_file' style='text-align:center'>"); 

    $.ajax({
        url: "actividades/aux",
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#actividades").append("<label>Actividad/es</label><select multiple class='campo form-control' name='actividades[]' value='' id='actividades-list' style='text-align:center'></select>");
            var choicesMulti = Array();
            $.each(data,function(index,value){
                var selected = false;
                if(value['activa']){
                    choicesMulti.push({
                        value:value['id'],
                        label:value['nombre'],
                        selected:selected,
                    });
                }
            });
            var selectClases = new Choices('#actividades-list', {
                choices: choicesMulti,
                removeItemButton: true,
                maxItemCount:-1,
                searchResultLimit:-1,
                renderChoiceLimit:-1,
                allowHTML:false,
                searchFields:"label",
                noResultsText: 'Sin resultados',
                noChoicesText:'Sin resultados',
                placeholderValue:'Selecciona...',
                itemSelectText: "",
            }); 
        }
    });

    
})
$(document).on('click','.borraImagen',function(){
    Swal.fire({
        title: "¿Estás seguro de borrar la imagen?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: 'No eliminar'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            var id = this.id.substr(7,20);
            $.ajax({
                url: "socios/deleteImage/"+id,
                type: "GET",
                dataType: "json",
                success: function(data){
                    console.log(data);
                    $("#contenedor-avatar").empty();
                    $("#contenedor-avatar").append("<img src='storage/avatar/noavatar.jpg' class='d-block' width='300px'><div class='mb-3'><input name='avatar' type='file' class='form-control' id='formFile'></div>");
                }
            }); 
        } else if (result.isDenied) {
          Swal.fire("La imagen no se eliminó", "", "info");
        }
      });
});
$(document).on("change","#fichaSocio #avatar_file",function(event){
    var tmppath = URL.createObjectURL(event.target.files[0]);
    $("#contenedor-avatar img").fadeIn("fast").attr('src',tmppath);
});
$(document).on("change","#seleccion-avatar",function(event){
    var tmppath = URL.createObjectURL(event.target.files[0]);
    $("#contenedor-avatar img").fadeIn("fast").attr('src',tmppath);
});
$(document).on("change","#camara-avatar",function(event){
    var tmppath = URL.createObjectURL(event.target.files[0]);
    $("#contenedor-avatar img").fadeIn("fast").attr('src',tmppath);
});

//HERRAMIENTAS CARDS
$(document).on('click','.nuevo-recibo',function(){
    $(".modal-content").css('background-color',"#E9F9E6");
    //Vaciamos campos
    $(".campoRecibo").val("");
    $(".campo_muestra").html("");
    $("#fichaReciboLabel").html("Creación de nuevo recibo<i class='bi bi-bootstrap-reboot ms-3 change-b'></i>");
    //Rellenamos campos
    var numero_socio = this.id.substr(13,20);
    $("#receptor").val(numero_socio).change();
    $("#fpago").val("efectivo").change();
    $("#iva").val("21").change();
    $("#fecha_emision").val(new Date().toISOString().substr(0,10)).change();
    //Formateamos formulario
    $("#btnDeleteRecibo").css("display","none");
    $("#formRecibo").attr('action',"recibos");
    $("#_method").val("POST");
    //Rellenamos concepto e importe
    var concepto = "";
    $.ajax({
        url: "socios/aux/"+numero_socio,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#items-recibo").empty();
            $("#items-recibo").append(`
                <div class="cabecera-items" style='position:sticky; top:0; background-color:#fff'> 
                    <div class="cabecera-item-recibo-item">
                        <p class='m-auto text-center mb-1 fw-bolder'>Item#</p>
                    </div>
                    <div class="cabecera-item-recibo-nombre"'>
                        <p class='m-auto text-center mb-1 fw-bolder'>Nombre</p>
                    </div>
                    <div class="cabecera-item-recibo-iva">
                        <p class='m-auto text-center mb-1 fw-bolder'>Iva</p>
                    </div>
                    <div class="cabecera-item-recibo-cantidad">
                        <p class='m-auto text-center mb-1 fw-bolder'>Cant</p>
                    </div>
                    <div class="cabecera-item-recibo-pvp">
                        <p class='m-auto text-center mb-1 fw-bolder'>PVP</p>
                    </div>
                    <div class="cabecera-item-recibo-descuento">
                        <p class='m-auto text-center mb-1 fw-bolder'>Descuento %</p>
                    </div>
                    <div class="cabecera-item-recibo-importe">
                        <p class='m-auto text-center mb-1 fw-bolder'>Importe</p>
                    </div>
                    <div class="cabecera-item-recibo-hasta">
                        <p class='m-auto text-center mb-1 fw-bolder'><i class="bi bi-calendar-event me-1"></i>Hasta</p>
                    </div>
                    <div class="cabecera-item-recibo-borrar"></div>
                </div>
            `);
            $.each(data['assigned_actividades'],function(index,value){
                if(value['activa'] == 1){
                    //Si las actividad es de mes completo
                    if(value['mes_completo'] == 1){
                        console.log("Mes completo");
                        //Si tiene fecha hasta
                        if(data['fecha_hasta'] != null){
                            console.log("Tiene fecha hasta");
                            //Si la fecha hasta es menor que el mes de la fecha actual
                            if((parseInt(data['fecha_hasta'].substr(0,4)) < parseInt(fecha.getFullYear())) || (parseInt(data['fecha_hasta'].substr(5,2)) < parseInt(fecha.getMonth())+1 && parseInt(data['fecha_hasta'].substr(0,4)) == parseInt(fecha.getFullYear()))){
                                console.log("Fecha hasta menor que mes actual");
                                var fecha_desde = fecha.getFullYear()+'-'+(pad(fecha.getMonth()+1,2))+'-01';
                                var fecha_hasta = fecha.getFullYear()+'-'+(pad(fecha.getMonth()+1,2))+'-'+ultimo_dia_mes[fecha.getMonth()];
                            //Si el mes de fecha hasta es igual o mayor que el mes actual
                            }else{
                                console.log("Fecha hasta igual o mayor que mes actual");
                                //Si el mes actual es menor que 12, es decir, no es diciembre
                                if(parseInt(data['fecha_hasta'].substr(5,2))+1 < 12){
                                    console.log("Mes actual menor que 12");
                                    var fecha_desde = parseInt(data['fecha_hasta'].substr(0,4))+'-'+(pad(parseInt(data['fecha_hasta'].substr(5,2))+1,2))+'-01';
                                    var fecha_hasta = parseInt(data['fecha_hasta'].substr(0,4))+'-'+(pad(parseInt(data['fecha_hasta'].substr(5,2))+1,2))+'-'+ultimo_dia_mes[parseInt(data['fecha_hasta'].substr(5,2))];
                                //Si el mes actual es diciembre
                                }else{
                                    console.log("Mes actual diciembre");
                                    var fecha_desde = pad(parseInt(data['fecha_hasta'].substr(0,4))+1,4)+'-'+(pad(1,2))+'-01';
                                    var fecha_hasta = pad(parseInt(data['fecha_hasta'].substr(0,4))+1,4)+'-'+(pad(1,2))+'-'+ultimo_dia_mes[0];
                                }
                            }
                        //Si no tiene fecha hasta
                        }else{
                            console.log("No tiene fecha hasta");
                            //Si el mes actual es menor que 12, es decir, no es diciembre
                            if(fecha.getMonth()+1 < 12){
                                console.log("Mes actual menor que 12");
                                var fecha_desde = fecha.getFullYear()+'-'+(pad(fecha.getMonth()+1,2))+'-01';
                                var fecha_hasta = fecha.getFullYear()+'-'+(pad(fecha.getMonth()+1,2))+'-'+ultimo_dia_mes[fecha.getMonth()];
                            //Si el mes actual es diciembre
                            }else{
                                console.log("Mes actual diciembre");
                                var fecha_desde = pad(parseInt(fecha.getFullYear())+1,4)+'-'+(pad(1,2))+'-01';
                                var fecha_hasta = pad(parseInt(fecha.getFullYear())+1,4)+'-'+(pad(1,2))+'-'+ultimo_dia_mes[0];
                            }
                        }
                    //Si la actividad no es de mes completo
                    }else{
                        console.log("No mes completo");
                        //Si la el dia de la fecha actual es menor que 15, es decir, estamos en la primera quincena
                        if(fecha.getDate() < 15){
                            console.log("Primera quincena");
                            //Si tiene fecha hasta
                            if(data['fecha_hasta'] != null){
                                console.log("Tiene fecha hasta");
                                //Si el dia de la fecha hasta es 14
                                if(data['fecha_hasta'].substr(8,2) == 14){
                                    console.log("Dia de fecha hasta 14");
                                    //Si el mes de fecha hasta es igual o mayor que el mes actual
                                    if((data['fecha_hasta'].substr(5,2) >= fecha.getMonth()+1 && data['fecha_hasta'].substr(0,4) == fecha.getFullYear()) || (data['fecha_hasta'].substr(0,4) > fecha.getFullYear())){
                                        console.log("Mes de fecha hasta igual o mayor que mes actual");
                                        //Si el mes de fecha hasta es menor que 12, es decir, no es diciembre
                                        if(parseInt(data['fecha_hasta'].substr(5,2))+1 < 12){
                                            console.log("Mes de fecha hasta menor que 12");
                                            var fecha_desde = pad(parseInt(data['fecha_hasta'].substr(0,4)),4) + '-' + pad(parseInt(data['fecha_hasta'].substr(5,2)),2) + '-15';
                                            var fecha_hasta = pad(parseInt(data['fecha_hasta'].substr(0,4)),4) + '-' + pad(parseInt(data['fecha_hasta'].substr(5,2)) + 1,2) + '-14';
                                        //Si el mes de fecha hasta es diciembre
                                        }else{
                                            console.log("Mes de fecha hasta diciembre");
                                            var fecha_desde = pad(parseInt(data['fecha_hasta'].substr(0,4)),4) + '-' + pad(parseInt(data['fecha_hasta'].substr(5,2)),2) + '-15';
                                            var fecha_hasta = pad(parseInt(data['fecha_hasta'].substr(0,4) + 1),4) + '-' + pad(1,2) + '-14';
                                        }
                                    //Si el mes de fecha actual es menor que el mes actual
                                    }else{
                                        console.log("Mes de fecha hasta menor que mes actual");
                                        var fecha_desde = fecha.getFullYear() + '-' + (pad(fecha.getMonth()+1,2)) + '-01';
                                        var fecha_hasta = fecha.getFullYear() + '-' + (pad(fecha.getMonth()+1,2)) + '-'+ ultimo_dia_mes[fecha.getMonth()];
                                    }
                                //Si el dia de la fecha hasta es diferente de 14
                                }else{
                                    console.log("Dia de fecha hasta diferente de 14");
                                    //Si el mes de fecha hasta es igual o mayor que el mes actual
                                    if((data['fecha_hasta'].substr(5,2) >= fecha.getMonth()+1 && data['fecha_hasta'].substr(0,4) == fecha.getFullYear()) || (data['fecha_hasta'].substr(0,4) > fecha.getFullYear())){
                                        console.log("Mes de fecha hasta igual o mayor que mes actual");
                                        //Si el mes de fecha hasta es menor que 12, es decir, no es diciembre
                                        if(data['fecha_hasta'].substr(5,2)+1 < 12){
                                            console.log("Mes de fecha hasta menor que 12");
                                            var fecha_desde = data['fecha_hasta'].substr(0,4) + '-' + pad(parseInt(data['fecha_hasta'].substr(5,2)) + 1,2) + '-01';
                                            var fecha_hasta = data['fecha_hasta'].substr(0,4) + '-' + pad(parseInt(data['fecha_hasta'].substr(5,2)) + 1,2) + '-' + ultimo_dia_mes[parseInt(data['fecha_hasta'].substr(5,2))];
                                        //Si el mes de fecha hasta es diciembre
                                        }else{
                                            console.log("Mes de fecha hasta diciembre");
                                            var fecha_desde = pad(parseInt(data['fecha_hasta'].substr(0,4)) + 1,4) + '-' + pad(1,2) + '-01';
                                            var fecha_hasta = pad(parseInt(data['fecha_hasta'].substr(0,4)) + 1,4) + '-' + pad(1,2) + '-' + ultimo_dia_mes[0];
                                        }
                                    //Si el mes de fecha hasta es menor que el mes actual
                                    }else{
                                        console.log("Mes de fecha hasta menor que mes actual");
                                        var fecha_desde = fecha.getFullYear() + '-' + (pad(fecha.getMonth()+1,2)) + '-01';
                                        var fecha_hasta = fecha.getFullYear() + '-' + (pad(fecha.getMonth()+1,2)) + '-'+ ultimo_dia_mes[fecha.getMonth()];
                                    }
                                }
                            //Si no tiene fecha hasta
                            }else{
                                console.log("No tiene fecha hasta");
                                //Si el mes actual es menor que 12, es decir, no es diciembre
                                if(fecha.getMonth()+1 < 12){
                                    var fecha_desde = fecha.getFullYear()+'-'+(pad(fecha.getMonth()+1,2))+'-01';
                                    var fecha_hasta = fecha.getFullYear()+'-'+(pad(fecha.getMonth()+1,2))+'-'+ultimo_dia_mes[fecha.getMonth()];
                                //Si el mes actual es diciembre
                                }else{
                                    var fecha_desde = pad(fecha.getFullYear()+1,4)+'-'+(pad(1,2))+'-01';
                                    var fecha_hasta = pad(fecha.getFullYear()+1,4)+'-'+(pad(1,2))+'-'+ultimo_dia_mes[0];
                                }
                            }
                        //Si la el dia de la fecha actual es mayor o igual que 15, es decir, estamos en la segunda quincena
                        }else{
                            console.log("Segunda quincena");
                            //Si tiene fecha hasta
                            if(data['fecha_hasta'] != null){
                                console.log("Tiene fecha hasta");
                                //Si el dia de la fecha hasta es 14
                                if(data['fecha_hasta'].substr(8,2) == 14){
                                    console.log("Dia de fecha hasta 14");
                                    //Si el mes de fecha hasta es igual o mayor que el mes actual
                                    if((data['fecha_hasta'].substr(5,2) >= fecha.getMonth()+1 && data['fecha_hasta'].substr(0,4) == fecha.getFullYear()) || (data['fecha_hasta'].substr(0,4) > fecha.getFullYear())){
                                        console.log("Mes de fecha hasta igual o mayor que mes actual");
                                        //Si el mes de fecha hasta es menor que 12, es decir, no es diciembre
                                        if(parseInt(data['fecha_hasta'].substr(5,2))+1 < 12){
                                            console.log("Mes de fecha hasta menor que 12");
                                            var fecha_desde = pad(parseInt(data['fecha_hasta'].substr(0,4)),4) + '-' + pad(parseInt(data['fecha_hasta'].substr(5,2)),2) + '-15';
                                            var fecha_hasta = pad(parseInt(data['fecha_hasta'].substr(0,4)),4) + '-' + pad(parseInt(data['fecha_hasta'].substr(5,2)) + 1,2) + '-14';
                                        //Si el mes de fecha hasta es diciembre
                                        }else{
                                            console.log("Mes de fecha hasta diciembre");
                                            var fecha_desde = pad(parseInt(data['fecha_hasta'].substr(0,4)),4) + '-' + pad(parseInt(data['fecha_hasta'].substr(5,2)),2) + '-15';
                                            var fecha_hasta = pad(parseInt(data['fecha_hasta'].substr(0,4) + 1),4) + '-' + pad(1,2) + '-14';
                                        }
                                    //Si el mes de fecha actual es menor que el mes actual
                                    }else{
                                        console.log("Mes de fecha hasta menor que mes actual");
                                        //Si el mes actual es menor que 12, es decir, no es diciembre
                                        if(fecha.getMonth()+1 < 12){
                                            console.log("Mes actual menor que 12");
                                            var fecha_desde = fecha.getFullYear() + '-' + (pad(fecha.getMonth()+1,2)) + '-15';
                                            var fecha_hasta = fecha.getFullYear() + '-' + (pad(fecha.getMonth()+2,2)) + '-14';
                                        //Si el mes actual es diciembre
                                        }else{
                                            console.log("Mes actual diciembre");
                                            var fecha_desde = fecha.getFullYear() + '-' + (pad(fecha.getMonth()+1,2)) + '-15';
                                            var fecha_hasta = pad(fecha.getFullYear()+1,4) + '-' + (pad(1,2)) + '-14'; 
                                        }
                                    }
                                //Si el dia de la fecha hasta es diferente de 14
                                }else{
                                    console.log("Dia de fecha hasta diferente de 14");
                                    //Si el mes de fecha hasta es igual o mayor que el mes actual
                                    if((data['fecha_hasta'].substr(5,2) >= fecha.getMonth()+1 && data['fecha_hasta'].substr(0,4) == fecha.getFullYear()) || (data['fecha_hasta'].substr(0,4) > fecha.getFullYear())){
                                        console.log("Mes de fecha hasta igual o mayor que mes actual");
                                        //Si el mes de fecha hasta es menor que 12, es decir, no es diciembre
                                        if(data['fecha_hasta'].substr(5,2) < 12){
                                            console.log("Mes de fecha hasta menor que 12");
                                            var fecha_desde = data['fecha_hasta'].substr(0,4) + '-' + pad(parseInt(data['fecha_hasta'].substr(5,2)) + 1,2) + '-01';
                                            var fecha_hasta = data['fecha_hasta'].substr(0,4) + '-' + pad(parseInt(data['fecha_hasta'].substr(5,2)) + 1,2) + '-' + ultimo_dia_mes[pad(parseInt(data['fecha_hasta'].substr(5,2) + 1,2))];
                                        //Si el mes de fecha hasta es diciembre
                                        }else{
                                            console.log("Mes de fecha hasta diciembre");
                                            var fecha_desde = pad(parseInt(data['fecha_hasta'].substr(0,4)) + 1,4) + '-' + pad(1,2) + '-01';
                                            var fecha_hasta = pad(parseInt(data['fecha_hasta'].substr(0,4)) + 1,4) + '-' + pad(1,2) + '-' + ultimo_dia_mes[0];
                                        }
                                    //Si el mes de fecha hasta es menor que el mes actual
                                    }else{
                                        console.log("Mes de fecha hasta menor que mes actual");
                                        //Si el mes actual es menor que 12, es decir, no es diciembre
                                        if(fecha.getMonth()+1 < 12){
                                            console.log("Mes actual menor que 12");
                                            var fecha_desde = fecha.getFullYear() + '-' + (pad(fecha.getMonth()+1,2)) + '-15';
                                            var fecha_hasta = fecha.getFullYear() + '-' + (pad(fecha.getMonth()+2,2)) + '-14';
                                        //Si el mes actual es diciembre
                                        }else{
                                            console.log("Mes actual diciembre");
                                            var fecha_desde = fecha.getFullYear() + '-' + (pad(fecha.getMonth()+1,2)) + '-15';
                                            var fecha_hasta = pad(fecha.getFullYear()+1,4) + '-' + (pad(1,2)) + '-14';
                                        }
                                    }
                                }
                            //Si no tiene fecha hasta
                            }else{
                                console.log("No tiene fecha hasta");
                                //Si el mes actual es menor que 12, es decir, no es diciembre
                                if(fecha.getMonth()+1 < 12){
                                    console.log("Mes actual menor que 12");
                                    var fecha_desde = fecha.getFullYear() + '-' + (pad(fecha.getMonth()+1,2)) + '-15';
                                    var fecha_hasta = fecha.getFullYear() + '-' + (pad(fecha.getMonth()+2,2)) + '-14';
                                }else{
                                    console.log("Mes actual diciembre");
                                    var fecha_desde = fecha.getFullYear() + '-' + (pad(fecha.getMonth()+1,2)) + '-15';
                                    var fecha_hasta = pad(fecha.getFullYear() + 1,4) + '-' + (pad(1,2)) + '-14';
                                }
                            }
                        }
                    }
                    console.log(fecha_desde);
                    console.log(fecha_hasta);
                    $("#items-recibo").append(`
                        <div class="registro-items" style='position:sticky; top:0; background-color:#fff'> 
                            <div class="registro-item-recibo-item">
                                <input type="text" name="input_item_id[]" class='form-control m-auto text-center mb-1 input-item-id empresa-`+value['empresa_id']+`' readonly value="Actividad-`+value['id']+`">
                            </div>
                            <div class="registro-item-recibo-nombre">
                                <input name="input_item_nombre[]" class='form-control m-auto text-center mb-1 input-item-nombre empresa-`+value['empresa_id']+`' readonly value="`+value['nombre']+`">
                            </div>
                            <div class="registro-item-recibo-iva">
                                <input name="input_item_iva[]" class='form-control m-auto text-center mb-1 input-item-iva empresa-`+value['empresa_id']+`' readonly value='21'>
                            </div>
                            <div class="registro-item-recibo-cantidad">
                                <input name="input_item_cantidad[]" class="form-control m-auto text-center input-item-cantidad empresa-`+value['empresa_id']+`" type='number' step='1.00' value='1'>
                            </div>
                            <div class="registro-item-recibo-pvp">
                                <input name="input_item_pvp[]" class="form-control m-auto text-center input-item-pvp empresa-`+value['empresa_id']+`" readonly type='number' step='0.01' value='`+value['precio']+`'>
                            </div>
                            <div class="registro-item-recibo-descuento">
                                <input name="input_item_descuento[]" class="form-control m-auto text-center input-item-descuento empresa-`+value['empresa_id']+`" type='number' step='0.01' value='0'>
                            </div>
                            <div class="registro-item-recibo-importe">
                                <input type="number" name="input_item_importe[]" step="0.01" class='form-control m-auto text-center input-item-importe mb-1 empresa-`+value['empresa_id']+`' value='`+value['precio']+`'>
                            </div>
                            <div class="registro-item-recibo-hasta">
                                <input type="date" name="input_item_hasta[]" required class='form-control m-auto text-center input-item-hasta mb-1 empresa-`+value['empresa_id']+`' value='`+fecha_hasta+`'>
                                <input type="hidden" class="input-item-desde" value="`+fecha_desde+`">
                            </div>
                            <div class="registro-item-recibo-borrar">
                                <a title='Borrar' class='borrar-item empresa-`+value['empresa_id']+`' href='#'><i class="bi bi-trash3-fill"></i></a>
                            </div>
                        </div>
                    `); 
                    if(index == 0){
                        concepto += "Cuota "+value['nombre'];
                    }else{
                        concepto += " + Cuota "+value['nombre'];
                    }
                }
            });
            $("#concepto").val(concepto).change();
            rellenaEmisor();
        }
    });
});
$(document).on('click','.historial-recibos',function(){
    var id = this.id.substr(18,20);
    $.ajax({
        async: false,
        url: "socios/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#historialRecibosLabel").html("Historial de recibos de "+data['nombre']+" "+data['apellidos']);
            $("#recibos-lista").empty();
            $("#recibos-lista").append(`
                <div class="registro-historial" style='position:sticky; top:0; background-color:#fff'> 
                    <div class='d-flex flex-column w-100'>
                        <div class='d-flex flex-row align-items-center w-100'>
                            <div style='width:2%'>
                                            
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'># Recibo</p>
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Concepto</p>
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Fecha</p>
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Importe</p>
                            </div>
                            <div class='w-100'>
                                <p class='m-auto text-center mb-1 fw-bolder'>F.Pago</p>
                            </div>
                            <div style='width:2%'>
                                            
                            </div>
                        </div>
                    </div>
                </div>
            `);
            $.each(data.recibos,function(index,value){
                var html = `
                    <div class="registro-historial" id="registro-historal-`+value['id']+`"> 
                        <div class='d-flex flex-column w-100'>
                            <div class='d-flex flex-row align-items-center w-100'>
                                <div style='width:2%'>
                                    <i class="bi bi-caret-down-fill desplegable"></i>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'>`+value['serial']+`</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'>`+value['concepto']+`</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'><i class="bi bi-calendar-event me-1"></i>`+value['fecha_emision']+`</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'>`+value['importe']+`€</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1'>`+value['fpago']+`</p>
                                </div>
                                <div style='width:2%'>
                                    <a target='_blank' href='/recibos?campofiltro=serial&valorfiltro=`+value['serial']+`'><i class="bi bi-eye-fill"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                `;  
                $("#recibos-lista").append(html);
                $("#recibos-lista").append(`
                    <div class="d-none registro-historial-items registro-historial-items-`+value['id']+`"> 
                        <div class='d-flex flex-column w-100'>
                            <div class='d-flex flex-row align-items-center w-100'>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1 fw-bolder'>#Item</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1 fw-bolder'>IVA</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1 fw-bolder'>Cantidad</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1 fw-bolder'>PVP</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1 fw-bolder'>Descuento</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1 fw-bolder'>Importe</p>
                                </div>
                                <div class='w-100'>
                                    <p class='m-auto text-center mb-1 fw-bolder'>Fecha hasta</p>
                                </div>
                            </div>
                        </div>
                    </div>
                `);
                $.each(value['lineas'],function(index2,value2){
                    console.log(value2);
                    var html = `
                        <div class="d-none registro-historial-items registro-historial-items-`+value['id']+`"> 
                            <div class='d-flex flex-column w-100'>
                                <div class='d-flex flex-row align-items-center w-100'>
                                    <div class='w-100'>
                                        <p class='m-auto text-center mb-1'>`+value2['item_id']+`</p>
                                    </div>
                                    <div class='w-100'>
                                        <p class='m-auto text-center mb-1'>`+value2['iva']+`%</p>
                                    </div>
                                    <div class='w-100'>
                                        <p class='m-auto text-center mb-1'>`+value2['cantidad']+`ud</p>
                                    </div>
                                    <div class='w-100'>
                                        <p class='m-auto text-center mb-1'>`+value2['pvp']+`€</p>
                                    </div>
                                    <div class='w-100'>
                                        <p class='m-auto text-center mb-1'>`+value2['descuento']+`%</p>
                                    </div>
                                    <div class='w-100'>
                                        <p class='m-auto text-center mb-1'>`+value2['importe']+`€</p>
                                    </div>
                                    <div class='w-100'>
                                        <p class='m-auto text-center mb-1'><i class="bi bi-calendar-event me-1"></i>`+value2['fecha_hasta']+`</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    `;  
                    $("#recibos-lista").append(html);
                });
            });
        }
    });
});
$(document).on('click','.activar-socio',function(){
    $("#fechahasta").modal("show");
    var id    = this.id.substr(14,20);
    $("#fechahastaForm").attr('action',"socios/changestatus/"+id);
    $("#accion").val("activar");
});
$(document).on('click','.desactivar-socio',function(){
    var id = this.id.substr(17,20);
    Swal.fire({
        title: "¿Estás seguro que deseas desactivar a este socio?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Desactivar",
        denyButtonText: 'No desactivar'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            $("#form-desactivar-socio-"+id).submit();
        } else if (result.isDenied) {
          Swal.fire("El socio no se desactivó", "", "info");
        }
      });
});
$(document).on('click','.desplegable',function(){
    var icon = $(this);
    $.each(icon.attr('class').split(' '),function(index,value){
        console.log(value);
        if(value == 'bi-caret-down-fill'){
            $(".registro-historial-items-"+icon.parent().parent().parent().parent().attr('id').substr(18,100)).removeClass('d-none');
            icon.addClass('bi-caret-up-fill');
            icon.removeClass('bi-caret-down-fill');
        }
        if(value == 'bi-caret-up-fill'){
            $(".registro-historial-items-"+icon.parent().parent().parent().parent().attr('id').substr(18,100)).addClass('d-none');
            icon.addClass('bi-caret-down-fill');
            icon.removeClass('bi-caret-up-fill');
        }
    });
});
$(document).on('mouseover',"#socios-container .card-body",function(){
    $(this).parent().find(".card-img-top").removeClass('grey-filter');
});
$(document).on('mouseout',"#socios-container .card-body",function(){
    $(this).parent().find(".card-img-top").addClass('grey-filter');
});
$(document).on('click','.change-b',function(){
    if($(this).parent().parent().parent().css('background-color') == "rgb(249, 230, 233)"){
        $(this).parent().parent().parent().css('background-color',"#E9F9E6");
        $.ajax({
            url: "recibos/lastSerial/"+$("#emisor").val(),
            type: "GET",
            dataType: "json",
            success: function(data){
                $("#serial_recibo").html(data);
                $("#serial_recibo").css('color',"blue");
                $("#serial_recibo").css('font-weight',"bolder");
                $("#b").remove();
            }
        });
    }else if($(this).parent().parent().parent().css('background-color') == "rgb(233, 249, 230)"){
        $(this).parent().parent().parent().css('background-color',"#F9E6E9");
        $.ajax({
            url: "recibos/lastSerial/99",
            type: "GET",
            dataType: "json",
            success: function(data){
                console.log(data);
                $("#serial_recibo").html(data);
                $("#serial_recibo").css('color',"red");
                $("#serial_recibo").css('font-weight',"bolder");
                $("#formRecibo").append("<input id='b' class='d-none' name='b' type='checkbox' checked>");
            }
        });
    }
    
});
$(document).on('click','.btnDeleteSocio',function(){
    Swal.fire({
        title: "¿Estás seguro que deseas eliminar a este socio?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: 'No eliminar'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            $("#form"+this.id).submit();
        } else if (result.isDenied) {
          Swal.fire("El socio no se eliminó", "", "info");
        }
      });
});
$(document).on('click','.ver-socio-pendiente',function(){
    $("#fichaSocio").modal("show");
    var id = this.id.substr(16,25);
    $("#_method").val("PUT");
    $("#formSocio").attr('action',"socios/"+id);
    $(".btnDeleteSocio").css("display","block");
    $("#avatar").attr('src',"storage/avatar/noavatar.jpg");
    $("#actividades").empty();
    $(".campoSocio").val("");
    $("#formSocio .modal-footer").empty();
    $("#formSocio .modal-footer").append(`
        <div class="d-flex" style="width:30%">
            <a href="#" onclick="" id="delete-`+id+`" class="btn btn-danger ms-2 me-2 btnDeleteSocio" style="display: block;">Eliminar</a>
        </div>
        <div class="d-flex" style="width:35%">
            <a href="#" class="btn btn-secondary m-1 cambio-avatar" id="cambio-avatar-`+id+`" title="Notificar cambio de avatar">Notificar cambio de avatar<i class="ms-1 bi bi-send-exclamation-fill"></i></a>
            <a href="#" class="btn btn-secondary m-1 aprobar-socio" id="aprobar-socio-`+id+`" title="Aprobar socio">Aprobar socio<i class="ms-1 bi bi-cloud-upload-fill"></i></a>
        </div>
        <div class="d-flex flex-row-reverse" style="width:30%">
            <button type="button" class="btn btn-secondary ms-2 me-2" data-bs-dismiss="modal">Cerrar</button>
            <button type="submit" class="btn btn-primary boton">Guardar</button>
        </div>
    `);

    $.ajax({
        url: "socios/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaSocioLabel").html("Aprobar socio "+data['nombre']+" "+data['apellidos']);
            $.each(data,function(index,value){
                if(index == 'avatar'){
                    $("#contenedor-avatar").empty();
                    if(value === null){
                        $("#contenedor-avatar").append("<img src='storage/avatar/noavatar.jpg' class='d-block' width='300px'>");
                    }else{
                        $("#contenedor-avatar").append("<img id='image-"+data['id']+"' src='storage"+value+"' class='d-block' width='300px'><button  class='borraImagen' id='imgdel-"+data['id']+"' type='button'><i class='bi bi-trash3'></i></button>");
                    }
                   $("#contenedor-avatar").append("<input type='file' accept='.jpg,.png,.jpeg' class='campo form-control' name='avatar_file' id='avatar_file' style='text-align:center'>"); 
                }
                if(index == "actividades" && value!= null){
                    var choicesMulti = Array();
                    var valorselect = Array();
                    $("#actividades").append("<label>Actividad/es</label><select multiple class='campo form-control' name='actividades[]' value='' id='actividades-list' style='text-align:center'></select>");
                    $.each(value,function(index2,value2){
                        var selected = false;
                        if(value2['activa']){
                            $.each(data['assigned_actividades'],function(index3,value3){
                                valorselect.push(value3['id']);
                                if(value2['id'] == value3['id']){
                                    selected = true;
                                }
                            });
                            choicesMulti.push({
                                value:value2['id'],
                                label:value2['nombre'],
                                selected:selected,
                            });
                        }
                    });
                    var selectClases = new Choices('#actividades-list', {
                        choices: choicesMulti,
                        removeItemButton: true,
                        maxItemCount:-1,
                        searchResultLimit:-1,
                        renderChoiceLimit:-1,
                        allowHTML:false,
                        searchFields:"label",
                        noResultsText: 'Sin resultados',
                        noChoicesText:'Sin resultados',
                        placeholderValue:'Selecciona...',
                        itemSelectText: "",
                    }); 
                    $("#actividades").val(valorselect);
                }else{
                    $("#"+index.toLowerCase()).val(value);
                }
            });
        }
    }); 
});
$(document).on('click','.aprobar-socio',function(){
    Swal.fire({
        title: "¿Estás seguro que deseas incorporar a este socio a la base de datos?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Si, incorporar",
        denyButtonText: 'No incorporar'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            var id = this.id.substr(14,25);
            $.ajax({
                url: "socios/aprobarsocio/"+id,
                type: "GET",
                dataType: "json",
                success: function(data){
                    if(data == true){
                        location.reload();
                    }
                }
            });
        } else if (result.isDenied) {
          Swal.fire("El socio no se incorporó a la base de datos", "", "info");
        }
      });
});
$(document).on('click','.cambio-avatar',function(){
    Swal.fire({
        title: "¿Estás seguro que deseas enviar un email a este socio para que cambie su avatar?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Si, enviar",
        denyButtonText: 'No enviar'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            var id = this.id.substr(14,25);
            $.ajax({
                url: "socios/notificacion/"+id,
                type: "GET",
                dataType: "json",
                data: {asunto:"Cambio de avatar",mensaje:"Por favor, cambia tu avatar para poder ser aprobado como socio.<br><a style='color:#ffffff' href='https://fitnessfabra.basicsoftgym.es' target='_blank'>Accede a tu perfil</a>"},
                success: function(data){
                    if(data == true){
                        Swal.fire("Email enviado", "", "success");
                    }else{
                        Swal.fire("El email no se pudo enviar", "", "error");
                    }
                }
            });
        } else if (result.isDenied) {
          
        }
      });
});
//Validacion DNI/NIE
$(document).on('change',"#dni",function(){
    if($("#tipo_documento").val() == "DNI"){
        $("#dni").attr('pattern','[0-9]{8}[A-Z]{1}');
    }else if($("#tipo_documento").val() == "NIE"){
        $("#dni").attr('pattern','[XYZ]{1}[0-9]{7}[A-Z]{1}');
    }else{
        $("#dni").attr('pattern',null);
    }
});
$(document).on('change',"#tipo_documento",function(){
    if($("#tipo_documento").val() == "DNI"){
        $("#dni").attr('pattern','[0-9]{8}[A-Z]{1}');
    }else if($("#tipo_documento").val() == "NIE"){
        $("#dni").attr('pattern','[XYZ]{1}[0-9]{7}[A-Z]{1}');
    }else{
        $("#dni").attr('pattern',null);
    }
});
//FUNCIONES
function recalculoimporte(){
    var lineas = $('.registro-items');
    var importe_total = 0.00;
    $.each(lineas,function(){
        if($(this).find('.input-item-importe').prop('disabled') == false){
            importe_total = importe_total + parseFloat($(this).find('.input-item-importe').val());
        }
    });
    $("#importe").val(importe_total).change();
}
function construccionconcepto(){
    var lineas   = $('.registro-items');
    var concepto = "";
    var meses    = ['ENERO','FEBRERO','MARZO','ABRIL','MAYO','JUNIO','JULIO','AGOSTO','SEPTIEMBRE','OCTUBRE','NOVIEMBRE','DICIEMBRE'];
    var fecha    = new Date();
    $.each(lineas,function(index,value){
        if($(this).find('.input-item-id').prop('disabled') == false){
            if($(this).find('.input-item-id').val().split("-")[0] == "Actividad"){
                if($(this).find('.input-item-desde').val().substr(8,2) == "01"){
                    concepto += " // Cuota "+meses[$(this).find('.input-item-hasta').val().substr(5,2) - 1]+" "+$(this).find('.input-item-nombre').val();                    
                }else{
                    concepto += " // Cuota "+meses[$(this).find('.input-item-hasta').val().substr(5,2) - 1]+" "+$(this).find('.input-item-nombre').val()+" hasta "+$(this).find('.input-item-hasta').val().substr(8,2)+"-"+$(this).find('.input-item-hasta').val().substr(5,2)+"-"+$(this).find('.input-item-hasta').val().substr(0,4);
                }
            }else{
                concepto +=  " // "+$(this).find('.input-item-nombre').val();
            }
        }
    });
    $("#concepto").val(concepto).change();
}
function rellenaEmisor(){
    var optionsEmisor;
    $.ajax({
        url: "empresas/aux",
        type: "GET",
        dataType: "json",
        success: function(data){
            $(".registro-items input").attr('disabled',true);
            $.each(data,function(index,value){
                if($(".empresa-"+value['id']).length > 0){
                    optionsEmisor += "<option selected value='"+value['id']+"'>"+value['nombre']+"</option>";
                    $("#emisor-nombre").html(value['nombre']);
                    $("#emisor-cif").html(value['cif']);
                    $("#emisor-direccion").html(value['direccion']);
                    $("#emisor-cpostal").html(value['cpostal']);
                    $("#emisor-poblacion").html(value['poblacion']);
                    $("#emisor-telefono").html(value['telefono']);
                    $("#emisor-web").html(value['web']);
                    $("#logo-recibo img").attr('src',"storage/"+value['logo']);
                }else{
                    optionsEmisor += "<option value='"+value['id']+"'>"+value['nombre']+"</option>";
                }
            });
            $("#emisor").empty();
            $("#emisor").append(optionsEmisor);
            $(".empresa-"+$("#emisor").val()).attr('disabled',false);
            $.ajax({
                url: "recibos/lastSerial/"+$("#emisor").val(),
                type: "GET",
                dataType: "json",
                success: function(data){
                    $("#serial_recibo").html(data);
                    $("#serial_recibo").css('color',"blue");
                    $("#serial_recibo").css('font-weight',"bolder");
                }
            });
            recalculoimporte();
            construccionconcepto();
        }
    });
}
function rellenaReceptor(numSoc){
    $.ajax({
        url: "socios/aux/"+numSoc,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#receptor-numsoc").html(data['numero_socio']);
            $("#receptor-socio").html(data['nombre']+ " "+data['apellidos']);
            $("#receptor-nif").html(data['dni']);
            $("#receptor-direccion").html(data['direccion']);
            $("#receptor-cpostal").html(data['cpostal']);
            $("#receptor-poblacion").html(data['poblacion']);
            $("#socio_id").val(data['id']);
            $("#receptor").val(numSoc);
        }
    });
}
function rellenaConceptoActividad(){
    var concepto = "";
    $.each($(".etiquetas"),function(index,value){
        if(index == 0){
            concepto = "Cuota " + $($(value).children("p")).html();
        }else{
            if($($(value).children("p")).html() != "Añadir producto"){
                concepto += "+ Cuota " + $($(value).children("p")).html(); 
            }
        }
    });
    $("#concepto").val(concepto).change();
}
function pad (str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}