//Select actividades
$("#horario_actividad_id").ready(function() {
    $.ajax({
        url: "/actividades/aux",
        type: "GET",
        dataType: "json",
        success: function(response) {
            $.each(response, function(index, value) {
                $("#horario_actividad_id").append('<option value="' + value.id + '">' + value.nombre + '</option>');
            });
        }
    });
});
//Select salas
$("#horario_sala_id").ready(function() {
    $.ajax({
        url: "/salas/aux",
        type: "GET",
        dataType: "json",
        success: function(response) {
            $.each(response, function(index, value) {
                $("#horario_sala_id").append('<option value="' + value.id + '">' + value.nombre + '</option>');
            });
        }
    });
});
$(".horario").ready(function() {
    $.ajax({
        url: "/horarios/aux",
        type: "GET",
        dataType: "json",
        success: function(response) {
            $.each(response, function(index, value) {
                $("."+value.dia.toLowerCase()+" ."+value.hora_inicio.substr(0,2)+value.hora_inicio.substr(3,2)).append("<div class='hora-actividad'>"+value.actividad.nombre+"</div>");
            });
        }
    });
});
//Filtro salas
$(document).on("click",".filtro-sala",function(){
    var id = this.id.substr(12,20);
    $(".linea-horario").css('display','none');
    $(".sala-"+id).css('display','flex');
    $("#imprimir-horario").attr("href","/horarios/imprimir/"+id);
    $("#enviar-horario").attr("href","/horarios/enviar/"+id);
});
$(document).on("click",".sinfiltro-sala",function(){
    $(".linea-horario").css('display','flex');
});
//Edicion horario
$(document).on("click",".cajaActividad",function(){
    var id = this.id.substr(8,20);
    $.ajax({
        url: "/horarios/"+id,
        type: "GET",
        dataType: "json",
        success: function(response) {

            $("#horario_actividad_id").val(response.actividad_id).change();
            $("#horario_sala_id").val(response.sala_id).change();
            $("#horario_hora_inicio").val(response.hora_inicio.substr(0,5)).change();
            $("#horario_hora_fin").val(response.hora_fin.substr(0,5)).change();
            $("#horario_dia").val(response.dia).change();
            $("#guardar_horario").html("Editar");

            $("#form-actividad-horario").attr("action","/horarios/"+id);
            $("#_method").val("PUT");

            $("#form-actividad-horario-delete").attr("action","/horarios/delete/"+id).removeClass("d-none");

        }
    });
});