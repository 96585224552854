$(document).on('click','.abreReciboModal', function(){
    
    var id = this.id.substr(7,25);
    $("#_method").val("PUT");
    $("#formRecibo").attr('action',"recibos/"+id);
    $("#btnDeleteRecibo").css("display","block");
    $("#btnDeleteRecibo").attr('onclick',"document.getElementById('delete-"+id+"').submit()");
    $("#imprime-recibo").val(id);
    $(".campoRecibo").val("");
    $(".campo_muestra").html("");
    var optionsEmisor;
    var optionsRecetor;
    $.ajax({
        url: "recibos/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaReciboLabel").html("Edición de recibo");
            $.each(data,function(index,value){
                if(value != null){
                    if(index == "empresa_id"){
                        $.ajax({
                            url: "empresas/aux",
                            type: "GET",
                            dataType: "json",
                            success: function(data2){
                                $.each(data2,function(index2,value2){
                                    if(value2['id'] == value){
                                        optionsEmisor += "<option selected value='"+value2['id']+"'>"+value2['nombre']+"</option>";
                                        datosEmisor(value2['id']);
                                    }else{
                                        optionsEmisor += "<option value='"+value2['id']+"'>"+value2['nombre']+"</option>";
                                    }
                                });
                                $("#emisor").empty();
                                $("#emisor").append(optionsEmisor);
                                $("#emisor_fake").append(optionsEmisor);
                                $("#emisor").hide();
                                $("#emisor_fake").removeClass('d-none');
                            }
                        });
                    }else if(index == "socio_id"){
                        $.ajax({
                            url: "socios/"+value,
                            type: "GET",
                            dataType: "json",
                            success: function(data){
                                rellenaReceptor(data['numero_socio']);
                                $("#receptor").val(data['numero_socio']);
                            }
                        });
                    }else if(index == "lineas"){
                        $("#items-recibo").empty();
                        $("#items-recibo").append(`
                            <div class="cabecera-items" style='position:sticky; top:0; background-color:#fff'> 
                                <div class="cabecera-item-recibo-item">
                                    <p class='m-auto text-center mb-1 fw-bolder'>Item#</p>
                                </div>
                                <div class="cabecera-item-recibo-nombre"'>
                                    <p class='m-auto text-center mb-1 fw-bolder'>Nombre</p>
                                </div>
                                <div class="cabecera-item-recibo-iva">
                                    <p class='m-auto text-center mb-1 fw-bolder'>Iva</p>
                                </div>
                                <div class="cabecera-item-recibo-cantidad">
                                    <p class='m-auto text-center mb-1 fw-bolder'>Cant</p>
                                </div>
                                <div class="cabecera-item-recibo-pvp">
                                    <p class='m-auto text-center mb-1 fw-bolder'>PVP</p>
                                </div>
                                <div class="cabecera-item-recibo-descuento">
                                    <p class='m-auto text-center mb-1 fw-bolder'>Descuento %</p>
                                </div>
                                <div class="cabecera-item-recibo-importe">
                                    <p class='m-auto text-center mb-1 fw-bolder'>Importe</p>
                                </div>
                                <div class="cabecera-item-recibo-hasta">
                                    <p class='m-auto text-center mb-1 fw-bolder'><i class="bi bi-calendar-event me-1"></i>Hasta</p>
                                </div>
                                <div class="cabecera-item-recibo-borrar"></div>
                            </div>
                        `);
                        $.each(value, function(index2,value2){
                            console.log(value2);
                            $("#items-recibo").append(`
                                <div class="registro-items" style='position:sticky; top:0; background-color:#fff'> 
                                    <div class="registro-item-recibo-item">
                                        <input type="text" name="input_item_id[]" class='form-control m-auto text-center mb-1 input-item-id' readonly value='`+value2['item_id']+`'>
                                    </div>
                                    <div class="registro-item-recibo-nombre">
                                        <input type="text" name="input_item_nombre[]" class='form-control m-auto text-center mb-1 input-item-nombre' readonly value='`+value2['datos']['nombre']+`'>
                                    </div>
                                    <div class="registro-item-recibo-iva">
                                        <input type="number" name="input_item_iva[]" class='form-control m-auto text-center mb-1 input-item-iva' readonly value='`+value2['iva']+`'>
                                    </div>
                                    <div class="registro-item-recibo-cantidad">
                                        <input name="input_item_cantidad[]" class="form-control m-auto text-center input-item-cantidad" type='number' step='1.00' value='`+value2['cantidad']+`' >
                                    </div>
                                    <div class="registro-item-recibo-pvp">
                                        <input name="input_item_pvp[]" class="form-control m-auto text-center input-item-pvp" readonly type='number' step='0.01' value='`+value2['pvp']+`'>
                                    </div>
                                    <div class="registro-item-recibo-descuento">
                                        <input name="input_item_descuento[]" class="form-control m-auto text-center input-item-descuento" type='number' step='0.01' value='`+value2['descuento']+`'>
                                    </div>
                                    <div class="registro-item-recibo-importe">
                                        <input type="number" step="0.01" name="input_item_importe[]" class='form-control m-auto text-center mb-1 input-item-importe' value="`+value2['importe']+`">
                                    </div>
                                    <div class="registro-item-recibo-hasta">
                                        <input type="date" name="input_item_hasta[]" class='form-control m-auto text-center input-item-hasta mb-1' value='`+value2['fecha_hasta']+`'>
                                    </div>
                                    <div class="registro-item-recibo-borrar">
                                        <a title='Borrar' class='borrar-item' href='#'><i class="bi bi-trash3-fill"></i></a>
                                    </div>
                                </div>
                            `); 
                        });
                    }else{
                        $("#"+index.toLowerCase()).val(value);
                        $("#"+index.toLowerCase()+"_recibo").html(value);
                    }
                }

            });
        }
    }); 
});
$(document).on('click','.nuevoReciboModal', function(){
    $("#_method").val("POST");
    $("#formRecibo").attr('action',"recibos");
    $("#fichaReciboLabel").html("Creación de nuevo recibo");
    $("#btnDeleteRecibo").css("display","none");
    $(".campoRecibo").val("");
    $(".campo_muestra").html("");
    var optionsEmisor;
    $.ajax({
        url: "empresas/aux",
        type: "GET",
        dataType: "json",
        success: function(data){
            $.each(data,function(index,value){
                optionsEmisor += "<option selected value='"+value['id']+"'>"+value['nombre']+"</option>";
                datosEmisor(value['id']);
            });
            $("#emisor").empty();
            $("#emisor").append(optionsEmisor);
            $("#emisor").show();
            $("#emisor_fake").addClass('d-none');
            
            $.ajax({
                url: "recibos/lastSerial/"+$("#emisor").val(),
                type: "GET",
                dataType: "json",
                success: function(data){
                    $("#serial_recibo").html(data);
                    $("#serial_recibo").css('color',"blue");
                    $("#serial_recibo").css('font-weight',"bolder");
                }
            });
        }
    });
    $("#items-recibo").empty();
    $("#items-recibo").append(`
        <div class="cabecera-items" style='position:sticky; top:0; background-color:#fff'> 
            <div class="cabecera-item-recibo-item">
                <p class='m-auto text-center mb-1 fw-bolder'>Item#</p>
            </div>
            <div class="cabecera-item-recibo-nombre"'>
                <p class='m-auto text-center mb-1 fw-bolder'>Nombre</p>
            </div>
            <div class="cabecera-item-recibo-iva">
                <p class='m-auto text-center mb-1 fw-bolder'>Iva</p>
            </div>
            <div class="cabecera-item-recibo-cantidad">
                <p class='m-auto text-center mb-1 fw-bolder'>Cant</p>
            </div>
            <div class="cabecera-item-recibo-pvp">
                <p class='m-auto text-center mb-1 fw-bolder'>PVP</p>
            </div>
            <div class="cabecera-item-recibo-descuento">
                <p class='m-auto text-center mb-1 fw-bolder'>Descuento %</p>
            </div>
            <div class="cabecera-item-recibo-importe">
                <p class='m-auto text-center mb-1 fw-bolder'>Importe</p>
            </div>
            <div class="cabecera-item-recibo-hasta">
                <p class='m-auto text-center mb-1 fw-bolder'><i class="bi bi-calendar-event me-1"></i>Hasta</p>
            </div>
            <div class="cabecera-item-recibo-borrar"></div>
        </div>
    `);
})
$(document).on('change','#emisor',function(){
    var id = $(this).val();
    console.log(id);
    $(".registro-items input").attr('disabled',true);
    $(".empresa-"+id).attr('disabled',false);
    $.ajax({
        url: "recibos/lastSerial/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#serial_recibo").html(data);
            $("#serial_recibo").css('color',"blue");
            $("#serial_recibo").css('font-weight',"bolder");
        }
    });
    $.ajax({
        url: "empresas/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#emisor-nombre").html(data['nombre']);
            $("#emisor-cif").html(data['cif']);
            $("#emisor-direccion").html(data['direccion']);
            $("#emisor-cpostal").html(data['cpostal']);
            $("#emisor-poblacion").html(data['poblacion']);
            $("#emisor-telefono").html(data['telefono']);
            $("#emisor-web").html(data['web']);
            $("#logo-recibo img").attr('src',"storage/"+data['logo']); 
        }
    });
    recalculoimporte();
    construccionconcepto();
});
$(document).on('change','#receptor',function(){
    rellenaReceptor($(this).val());
});
$(document).on('change','#fecha_emision',function(){
    $("#fecha_emision_recibo").html($(this).val());
});
$(document).on('change','#importe',function(){
    $("#importe_recibo").html($(this).val());
});
$(document).on('change','#fpago',function(){
    $("#fpago_recibo").html($(this).val());
});
$(document).on('change','#iva',function(){
    $("#iva_recibo").html($(this).val());
});
$(document).on('change','#concepto',function(){
    $("#concepto_recibo").addClass('capitalise').html($(this).val());
});
//ITEMS RECIBO
$(document).on('click','.addproducto',function(){
    $("#listaProductos").modal("show");
    $("#listaProductosLabel").empty();   
    $("#productos-lista").empty(); 
    $("#listaProductosLabel").append("Lista de productos");
    $.ajax({
        url: "productos/aux/",
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#productos-lista").append(`
                <div class="cabecera-lista" style='position:sticky; top:0; background-color:#fff; border-top:line solid thin'> 
                    <div class='d-flex flex-column w-100'>
                        <div class='d-flex flex-row align-items-center w-100'>
                            <div style='width:60%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Nombre</p>
                            </div>
                            <div style='width:15%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>PVP</p>
                            </div>
                            <div style='width:15%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Stock</p>
                            </div>
                            <div style='width:10%'>
                                <p class='m-auto text-center mb-1 fw-bolder'>Elegir</p>
                            </div>
                        </div>
                    </div>
                </div>
            `);
            $.each(data,function(index,value){
                if(value['stock'] > 0 && value['empresa_id'] == $("#emisor").val()){
                    var html = `
                        <div class="registro-lista"> 
                            <div class='d-flex flex-column w-100'>
                                <div class='d-flex flex-row align-items-center w-100'>
                                    <div style='width:60%'>
                                        <p class='m-auto text-center mb-1 capitalise'>`+value['nombre']+`</p>
                                    </div>
                                    <div style='width:15%'>
                                        <p class='m-auto text-center mb-1'>`+value['pvp']+`€</p>
                                    </div>
                                    <div style='width:15%'>
                                        <p class='m-auto text-center mb-1'>`+value['stock']+`ud</p>
                                    </div>
                                    <div class="text-center" style='width:10%'>
                                        <input type='checkbox' value='`+value['id']+`'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    `;  
                    $("#productos-lista").append(html);
                }
            });

        }
    });


});
$(document).on('click',"#confirmaddproduct",function(){
    var productos = $("#listaProductos input");
    var nuevoConcepto = $("#concepto").val();
    $.each(productos,function(index,value){
        if($(value).is(':checked')){
            $.ajax({
                url: "productos/"+$(value).val(),
                type: "GET",
                dataType: "json",
                success: function(data){
                    $("#items-recibo").append(`
                        <div class="registro-items" style='position:sticky; top:0; background-color:#fff'> 
                            <div class="registro-item-recibo-item">
                                <input type="text" name='input_item_id[]' class='form-control m-auto text-center mb-1 input-item-id empresa-`+data['empresa_id']+`' readonly value='Producto-`+data['id']+`'>
                            </div>
                            <div class="registro-item-recibo-nombre">
                                <input type="text" name='input_item_nombre[]' class='form-control m-auto text-center mb-1 input-item-nombre empresa-`+data['empresa_id']+`' readonly value='`+data['nombre']+`'>
                            </div>
                            <div class="registro-item-recibo-iva">
                                <input type="number" name="input_item_iva[]" class='form-control m-auto text-center mb-1 input-item-iva empresa-`+data['empresa_id']+`' readonly value='`+data['iva']+`'>
                            </div>
                            <div class="registro-item-recibo-cantidad">
                                <input name="input_item_cantidad[]" class="form-control m-auto text-center input-item-cantidad empresa-`+data['empresa_id']+`" type='number' step='1.00' value='1'>
                            </div>
                            <div class="registro-item-recibo-pvp">
                                <input name="input_item_pvp[]" class="form-control m-auto text-center input-item-pvp empresa-`+data['empresa_id']+`" readonly type='number' step='0.01' value='`+data['pvp']+`'>
                            </div>
                            <div class="registro-item-recibo-descuento">
                                <input name="input_item_descuento[]" class="form-control m-auto text-center input-item-descuento empresa-`+data['empresa_id']+`" type='number' step='0.01' value='0'>
                            </div>
                            <div class="registro-item-recibo-importe">
                                <input type="number" step="0.01" name="input_item_importe[]" class='form-control m-auto text-center input-item-importe mb-1 empresa-`+data['empresa_id']+`' value='`+data['pvp']+`'>
                            </div>
                            <div class="registro-item-recibo-hasta">
                                <input type="date" name="input_item_hasta[]" class='form-control m-auto text-center input-item-hasta mb-1 empresa-`+data['empresa_id']+`' readonly value=''>
                            </div>
                            <div class="registro-item-recibo-borrar">
                                <a title='Borrar' class='borrar-item' href='#'><i class="bi bi-trash3-fill"></i></a>
                            </div>
                        </div>
                    `); 
                    nuevoConcepto += " + " + data['nombre'].toLowerCase();
                    $("#concepto").addClass('capitalise').val(nuevoConcepto).change();

                    recalculoimporte();
                    construccionconcepto();
                },
                async: false
            });
        }
    });
    $("#listaProductos").modal('hide');
});
$(document).on('keyup','#filtro-box-productos',function(){
    var value = $(this).val().toLowerCase();
		$("#productos-lista  .registro-lista").filter(function() {
			$(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
		});
});
$(document).on('click','.borrar-item',function(){
    $(this).parent().parent().remove();
    recalculoimporte();
    construccionconcepto();
});
$(document).on('change','.input-item-descuento',function(){
    var valor_descuento = $(this).val();
    var valor_pvp       = $(this).parent().parent().find(".input-item-pvp").val();
    var valor_cantidad  = $(this).parent().parent().find(".input-item-cantidad").val();
    $(this).parent().parent().find(".input-item-importe").val(Math.round10((valor_cantidad*valor_pvp)-((valor_cantidad*valor_pvp)*valor_descuento/100),-2));
    recalculoimporte();
});
$(document).on('change','.input-item-cantidad',function(){
    var valor_cantidad  = $(this).val();
    var valor_descuento = $(this).parent().parent().find(".input-item-descuento").val();
    var valor_pvp       = $(this).parent().parent().find(".input-item-pvp").val();
    $(this).parent().parent().find(".input-item-importe").val(Math.round10((valor_cantidad*valor_pvp)-((valor_cantidad*valor_pvp)*valor_descuento/100),-2));
    recalculoimporte();
});
$(document).on('change','.input-item-importe',function(){
    var valor_cantidad  = $(this).parent().parent().find(".input-item-cantidad").val();
    var valor_pvp       = $(this).parent().parent().find(".input-item-pvp").val();
    var valor_descuento = Math.round10((((valor_cantidad*valor_pvp)-$(this).val())*100)/(valor_cantidad*valor_pvp),-2);
    $(this).parent().parent().find(".input-item-descuento").val(valor_descuento);
    recalculoimporte();
});
//Contabilidad B
$(document).bind('keydown',function(e){
    if (typeof(keyPressed) == "undefined") {
        keyPressed = [];
    }
	var code = (e.keyCode ? e.keyCode : e.which);
 	keyPressed.push(code);
 	if (keyPressed.length === 2) {
	 	if (keyPressed[0] === 17 && keyPressed[1] === 66) {
             $(".card").css('display','none');
            $(".b").css('display','block');
	 		keyPressed = [];
	 		}
	 	else {
	 		keyPressed = [];
	 	}	
	 }
});
//FUNCIONES
function recalculoimporte(){
    var lineas = $('.registro-items');
    var importe_total = 0.00;
    $.each(lineas,function(){
        if($(this).find('.input-item-importe').prop('disabled') == false){
            importe_total = importe_total + parseFloat($(this).find('.input-item-importe').val());
        }
    });
    $("#importe").val(importe_total).change();
}
function construccionconcepto(){
    var lineas   = $('.registro-items');
    var concepto = "";
    var meses    = ['ENERO','FEBRERO','MARZO','ABRIL','MAYO','JUNIO','JULIO','AGOSTO','SEPTIEMBRE','OCTUBRE','NOVIEMBRE','DICIEMBRE'];
    var fecha    = new Date();
    $.each(lineas,function(index,value){
        if($(this).find('.input-item-id').prop('disabled') == false){
            if($(this).find('.input-item-id').val().split("-")[0] == "Actividad"){
                if($(this).find('.input-item-desde').val().substr(8,2) == "01"){
                    concepto += " // Cuota "+meses[$(this).find('.input-item-hasta').val().substr(5,2) - 1]+" "+$(this).find('.input-item-nombre').val();                    
                }else{
                    concepto += " // Cuota "+meses[$(this).find('.input-item-hasta').val().substr(5,2) - 1]+" "+$(this).find('.input-item-nombre').val()+" hasta "+$(this).find('.input-item-hasta').val().substr(8,2)+"-"+$(this).find('.input-item-hasta').val().substr(5,2)+"-"+$(this).find('.input-item-hasta').val().substr(0,4);
                }
            }else{
                concepto +=  " // "+$(this).find('.input-item-nombre').val();
            }
        }
    });
    $("#concepto").val(concepto).change();
}
function rellenaReceptor(numSoc){
    $.ajax({
        url: "socios/aux/"+numSoc,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#receptor-numsoc").html(data['numero_socio']);
            $("#receptor-socio").html(data['nombre']+ " "+data['apellidos']);
            $("#receptor-nif").html(data['dni']);
            $("#receptor-direccion").html(data['direccion']);
            $("#receptor-cpostal").html(data['cpostal']);
            $("#receptor-poblacion").html(data['poblacion']);
            $("#socio_id").val(data['id']);
        }
    });
}
function rellenaConceptoActividad(){
    var concepto = "";
    $.each($(".actividad"),function(index,value){
        if(index == 0){
            concepto = "Cuota " + $(value).html();
        }else{
            concepto += "+ Cuota " + $(value).html(); 
        }
    });
    $.each($(".producto"),function(index,value){
        if(index == 0 && concepto == ""){
            concepto = $(value).html();
        }else{
            concepto += " + "+$(value).html(); 
        }
    });
    $("#concepto").val(concepto).change();
}
function datosEmisor(id){
    $.ajax({
        url: "empresas/aux",
        type: "GET",
        dataType: "json",
        success: function(data){
            $.each(data,function(index,value){
                if(value['id'] == id){
                    $("#emisor-nombre").html(value['nombre']);
                    $("#emisor-cif").html(value['cif']);
                    $("#emisor-direccion").html(value['direccion']);
                    $("#emisor-cpostal").html(value['cpostal']);
                    $("#emisor-poblacion").html(value['poblacion']);
                    $("#emisor-telefono").html(value['telefono']);
                    $("#emisor-web").html(value['web']);
                    $("#logo-recibo img").attr('src',"storage/"+value['logo']);
                }
            });
        }
    });
}
function pad (str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}
(function () {
    /**
     * Ajuste decimal de un número.
     *
     * @param {String}  tipo  El tipo de ajuste.
     * @param {Number}  valor El numero.
     * @param {Integer} exp   El exponente (el logaritmo 10 del ajuste base).
     * @returns {Number} El valor ajustado.
     */
    function decimalAdjust(type, value, exp) {
      // Si el exp no está definido o es cero...
      if (typeof exp === "undefined" || +exp === 0) {
        return Math[type](value);
      }
      value = +value;
      exp = +exp;
      // Si el valor no es un número o el exp no es un entero...
      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
        return NaN;
      }
      // Shift
      value = value.toString().split("e");
      value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
    }
  
    // Decimal round
    if (!Math.round10) {
      Math.round10 = function (value, exp) {
        return decimalAdjust("round", value, exp);
      };
    }
    // Decimal floor
    if (!Math.floor10) {
      Math.floor10 = function (value, exp) {
        return decimalAdjust("floor", value, exp);
      };
    }
    // Decimal ceil
    if (!Math.ceil10) {
      Math.ceil10 = function (value, exp) {
        return decimalAdjust("ceil", value, exp);
      };
    }
  })();