//Productos
$(document).on('click','.abreProductoModal', function(){
    
    var id = this.id.substr(9,25);
    console.log(id);
    $("#_method").val("PUT");
    $("#formProducto").attr('action',"productos/"+id);
    $("#btnDeleteProducto").css("display","block");
    $("#btnDeleteProducto").attr('onclick',"document.getElementById('delete-"+id+"').submit()");
    $(".campo").val("");
    $.ajax({
        url: "productos/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaProductoLabel").html("Edición de producto "+data['nombre']);
            $.each(data,function(index,value){
                if(index == "activo"){
                    if(value == 0){
                        $("#activo").attr("checked",false);
                    }else{
                        $("#activo").attr("checked",true);
                    }
                }if(index == "empresas"){
                    $("#empresa_id").empty();
                    $.each(value,function(index2,value2){
                        if(data['empresa'] != null){
                            if(value2['id'] == data['empresa']['id']){
                                $("#empresa_id").append("<option selected value='"+value2['id']+"'>"+value2['nombre']+"</option>");
                            }else{
                                $("#empresa_id").append("<option value='"+value2['id']+"'>"+value2['nombre']+"</option>");
                            }
                        }else{
                            $("#empresa_id").append("<option value='"+value2['id']+"'>"+value2['nombre']+"</option>");
                        }
                    });
                }else{
                    $("#"+index.toLowerCase()).val(value);
                }
            });
        }
    }); 
});
$(document).on('click','.nuevoProductoModal', function(){
    $("#_method").val("POST");
    $("#formProducto").attr('action',"productos");
    $("#fichaProductoLabel").html("Creación de nuevo producto");
    $("#btnDeleteProducto").css("display","none");
    $("#btnReciboProducto").css("display","none");
    $(".campo").val("");
    $.ajax({
        url: "empresas/aux",
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#empresa_id").empty();
            $.each(data,function(index,value){
                $("#empresa_id").append("<option value='"+value['id']+"'>"+value['nombre']+"</option>");
            });
        }
    });
});