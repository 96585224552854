//Empresas
$(document).on('click','.abreEmpresaModal', function(){
    
    var id = this.id.substr(8,25);
    $("#_method").val("PUT");
    $("#formEmpresa").attr('action',"empresas/"+id);
    $("#btnDeleteEmpresa").css("display","block");
    $("#btnDeleteEmpresa").attr('onclick',"document.getElementById('delete-"+id+"').submit()");
    $("#logo").attr('src',"storage/logo/nologo.jpg");
    
    $(".campo").val("");
    $.ajax({
        url: "empresas/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaEmpresaLabel").html("Edición de empresa "+data['nombre']);
            $.each(data,function(index,value){
                if(value != null){
                    if(index == 'logo'){
                        $("#logo").attr('src',"storage/"+data['logo']);
                    }else{
                        $("#"+index.toLowerCase()).val(value);
                    }
                }

            });
        }
    }); 
});
$(document).on('click','.nuevaEmpresaModal', function(){
    $("#_method").val("POST");
    $("#formEmpresa").attr('action',"empresas");
    $("#fichaEmpresaLabel").html("Creación de nueva empresa");
    $("#logo").attr('src',"storage/logo/nologo.jpg");
    $("#btnDeleteEmpresa").css("display","none");
    $(".campo").val("");
});
$(document).on('click','.selectReporte',function(){
    var id = this.id.substr(14,25);
    $("#formSelectReporte").attr('action',"empresas/reportes/"+id);
    $("#_method").val("POST");
    $(".campo").val("");

    $.ajax({
        url: "empresas/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#selectReporteLabel").html("Selecciona reporte de la empresa "+data['nombre']);
        }
    }); 
});