//Actividades
$(document).on('click','.abreActividadModal', function(){
    
    var id = this.id.substr(10,25);
    $("#_method").val("PUT");
    $("#formActividad").attr('action',"actividades/"+id);
    $(".btnDeleteActividad").css("display","block");
    $(".btnDeleteActividad").attr('id',"delete-"+id);
    $(".campo").val("");

    $.ajax({
        url: "actividades/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#fichaActividadLabel").html("Edición de actividad "+data['nombre']);
            $.each(data,function(index,value){
                if(value != null){
                    if(index == "activa"){
                        if(value == 1){
                            $("#activa").prop("checked",true);
                        }
                    }if(index == "empresas"){
                        $("#empresa_id").empty();
                        $.each(value,function(index2,value2){
                            if(data['empresa'] != null){
                                if(value2['id'] == data['empresa']['id']){
                                    $("#empresa_id").append("<option selected value='"+value2['id']+"'>"+value2['nombre']+"</option>");
                                }else{
                                    $("#empresa_id").append("<option value='"+value2['id']+"'>"+value2['nombre']+"</option>");
                                }
                            }else{
                                $("#empresa_id").append("<option value='"+value2['id']+"'>"+value2['nombre']+"</option>");
                            }
                        });
                    }else if(index == "mes_completo"){
                        if(value == 1){
                            $("#mes_completo").append("<option value='1' selected>Mes completo</option>");
                            $("#mes_completo").append("<option value='0'>Mes fraccionado</option>");
                        }else{
                            $("#mes_completo").append("<option value='1'>Mes completo</option>");
                            $("#mes_completo").append("<option value='0' selected>Mes fraccionado</option>");
                        }
                    }else{
                        $("#"+index.toLowerCase()).val(value);
                    }
                }
            });
        }
    }); 
});
$(document).on('click','.nuevaActividadModal', function(){
    $("#_method").val("POST");
    $("#formActividad").attr('action',"actividades");
    $("#fichaActividadLabel").html("Creación de nueva actividad");
    $(".btnDeleteActividad").css("display","none");
    $(".campo").val("");
    $.ajax({
        url: "empresas/aux",
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#empresa_id").empty();
            $.each(data,function(index,value){
                $("#empresa_id").append("<option value='"+value['id']+"'>"+value['nombre']+"</option>");
            });
        }
    });
    $("#mes_completo").append("<option value='1'>Mes completo</option>");
    $("#mes_completo").append("<option value='0'>Mes fraccionado</option>");
    $("#color").val("white");
    $("#background_color").val("#483948");
})
$(document).on('click','.listado-clase',function(){
    var id = this.id.substr(14,20);
    $(".imprimir-listado").attr('id',"imprimir-listado-"+id);
    $.ajax({
        url: "actividades/"+id,
        type: "GET",
        dataType: "json",
        success: function(data){
            $("#listadoClaseLabel").html("Listado de socios de "+data['nombre']);
            $("#socios-lista").empty();
            $("#socios-lista").append(`
                <div class="registro-historial" style='position:sticky; top:0; background-color:#483948; color:#fff'> 
                    <div class='d-flex flex-column w-100'>
                        <div class='d-flex flex-row align-items-center w-100'>
                            <div style="width:10%">
                                <p class='m-auto text-center mb-1 fw-bolder'></p>
                            </div>
                            <div style="width:10%">
                                <p class='m-auto text-center mb-1 fw-bolder'># Socio</p>
                            </div>
                            <div style="width:30%">
                                <p class='m-auto text-center mb-1 fw-bolder'>Nombre y apellidos</p>
                            </div>
                            <div style="width:20%">
                                <p class='m-auto text-center mb-1 fw-bolder'>Edad</p>
                            </div>
                            <div style="width:20%">
                                <p class='m-auto text-center mb-1 fw-bolder'>Activo</p>
                            </div>
                            <div style="width:10%">
                                <p class='m-auto text-center mb-1 fw-bolder'></p>
                            </div>
                        </div>
                    </div>
                </div>
            `);
            $.each(data.assigned_socios,function(index,value){
                if(value['status'] == 1){
                    var html = `
                        <div class="registro-historial"> 
                            <div class='d-flex flex-column w-100'>
                                <div class='d-flex flex-row align-items-center w-100'>
                                    <div style="width:10%">
                                        <img src="storage`+value['avatar']+`">
                                    </div>
                                    <div style="width:10%">
                                        <p class='m-auto text-center mb-1'>`+value['numero_socio']+`</p>
                                    </div>
                                    <div style="width:30%">
                                        <p class='m-auto text-center mb-1'>`+value['nombre']+" "+value['apellidos']+`</p>
                                    </div>
                                    <div style="width:20%">
                                        <p class='m-auto text-center mb-1'>`+value['edad']+` años</p>
                                    </div>
                                    <div class="text-center" style="width:20%">
                                        <i class="bi bi-check-circle" style="color:green"></i>
                                    </div>
                                    <div class="text-center" style="width:10%">
                                        <a target='_blank' href='/socios?campofiltro=numero_socio&valorfiltro=`+value['numero_socio']+`'><i class="bi bi-eye-fill"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    `;  
                }else{
                    var html = `
                        <div class="registro-historial"> 
                            <div class='d-flex flex-column w-100'>
                                <div class='d-flex flex-row align-items-center w-100'>
                                    <div style="width:10%">
                                        <img src="storage`+value['avatar']+`">
                                    </div>
                                    <div style="width:10%">
                                        <p class='m-auto text-center mb-1'>`+value['numero_socio']+`</p>
                                    </div>
                                    <div style="width:30%">
                                        <p class='m-auto text-center mb-1'>`+value['nombre']+" "+value['apellidos']+`</p>
                                    </div>
                                    <div style="width:20%">
                                        <p class='m-auto text-center mb-1'>`+value['edad']+` años</p>
                                    </div>
                                    <div class="text-center" style="width:20%">
                                        <i class="bi bi-x" style="color:red"></i>
                                    </div>
                                    <div class="text-center" style="width:10%">
                                        <a target='_blank' href='/socios?campofiltro=numero_socio&valorfiltro=`+value['numero_socio']+`'><i class="bi bi-eye-fill"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    `; 
                }
                $("#socios-lista").append(html);
            });
        }
    });
});
$(document).on('click','.btnDeleteActividad',function(){
    Swal.fire({
        title: "Estás seguro que deseas eliminar a esta actividad?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: 'No eliminar'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            $("#"+this.id).submit();
        } else if (result.isDenied) {
          Swal.fire("La actividad no se eliminó", "", "info");
        }
      });
});
$(document).on('click','.imprimir-listado',function(){
    var id = this.id.substr(17,20);
    window.open("actividades/listapdf/"+id,"_blank");
});